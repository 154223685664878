import type { MergedUnion } from "@kablamo/kerosene";
import { useUpdatingRef } from "@kablamo/kerosene-ui";
import mapboxgl, { type MapboxEvent } from "mapbox-gl";
import maplibregl, { type LngLatLike, type MapLibreEvent } from "maplibre-gl";
import getConfig from "next/config";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MapLevels } from "../../../config/layers/layers";
import useAuthAccessToken from "../../../hooks/useAuthAccessToken";

type MapState =
  | {
      lib: "mapbox";
      map: mapboxgl.Map | null;
    }
  | {
      lib: "maplibre";
      map: maplibregl.Map | null;
    };

const VALID_MAP_LIBS = ["mapbox", "maplibre"] as const;

export type MapContextValue = {
  register: (container: HTMLDivElement) => void;
} & MapState;

export const MapContext = createContext<MapContextValue | undefined>(undefined);

export type MapBounds = [number, number, number, number];

export type MapProviderProps = {
  initialBounds?: MapBounds;
  children?: React.ReactNode;
  interactive?: boolean;
  onBoundsChange?: (bounds: MapBounds) => void;
  tiles?: "raster" | "vector";
} & MergedUnion<
  | {
      lib: "mapbox";
      /** Mapbox accessToken for recording map load quota and accessing terrain tiles */
      accessToken: string;
    }
  | {
      lib: "maplibre";
    }
>;

const { publicRuntimeConfig } = getConfig();
const { NEXT_HERE_API_KEY } = publicRuntimeConfig;

const BASE_MAP_TILES_URL = [
  "https://1.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://3.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
  "https://4.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/terrain.day/{z}/{x}/{y}/256/png8?apiKey=",
];

export const DEFAULT_CENTER: LngLatLike = [145.18, -32.609]; // NSW
export const DEFAULT_BOUNDS: MapBounds = [
  139.943848, -37.883254, 154.555664, -26.902477,
];
export const DEFAULT_INTERACTIVE = true;

const MAP_LEVEL_SOURCE = "mapLevelDummySource";
const TEST_MODE = globalThis?.location?.search.includes("test-mode");

const MapProvider = ({
  accessToken,
  children,
  initialBounds = DEFAULT_BOUNDS,
  interactive = DEFAULT_INTERACTIVE,
  lib,
  onBoundsChange,
  tiles = "raster",
}: MapProviderProps) => {
  if (!VALID_MAP_LIBS.includes(lib)) {
    throw new Error("MapProvider was rendered with an unknown lib");
  }

  const [state, setState] = useState<MapState>(() => ({ lib, map: null }));
  const authAccessTokenRef = useUpdatingRef(useAuthAccessToken());

  const initialBoundsRef = useRef(initialBounds);

  const mapSetupController = useRef(new AbortController());

  const register = useCallback(
    (container: HTMLDivElement) => {
      mapSetupController.current?.abort();

      if (!container) return;

      if (lib === "mapbox" && !accessToken) return;

      mapSetupController.current = new AbortController();

      const onError = (
        e: mapboxgl.MapEventType["error"] | maplibregl.MapEventType["error"],
        // eslint-disable-next-line no-console
      ) => console.error(e);

      // Note: Code duplication between Mapbox and MapLibre implementations is intentional here. Although the code is
      // mostly duplicated, combining the code would significantly increase the complexity and reduce type-safety.
      //
      // Please keep options in sync.

      if (lib === "mapbox") {
        const map = new mapboxgl.Map({
          accessToken,
          bounds: initialBoundsRef.current,
          container,
          style:
            tiles === "raster"
              ? {
                  version: 8,
                  sources: {
                    "basemap-raster-tiles": {
                      type: "raster",
                      tiles: BASE_MAP_TILES_URL.map(
                        (url) => `${url}${NEXT_HERE_API_KEY}`,
                      ),
                      tileSize: 256,
                    },
                  },
                  layers: [
                    {
                      id: "base-map-tiles",
                      type: "raster",
                      source: "basemap-raster-tiles",
                    },
                  ],
                  // TODO: Use Here Maps API (currently 404)
                  // glyphs: "https://assets.vector.hereapi.com/fonts/{fontstack}/{range}.pbf",
                  glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
                }
              : `https://assets.vector.hereapi.com/styles/berlin/base/mapbox/tilezen?apikey=${
                  NEXT_HERE_API_KEY
                }`,
          interactive,
          logoPosition: "bottom-left",
          attributionControl: false,
          renderWorldCopies: true,
          transformRequest: (url, resourceType) => {
            if (resourceType === "Tile" && url.includes("shared-athena")) {
              return {
                url,
                headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  Authorization: `Bearer ${authAccessTokenRef.current}`,
                },
              };
            }

            const nothing =
              // @ts-expect-error mapboxgl types are incorrect, undefined should be allowed
              undefined satisfies ReturnType<mapboxgl.TransformRequestFunction>;
            return nothing as unknown as ReturnType<mapboxgl.TransformRequestFunction>;
          },
        });
        const onLoad = () => {
          map.addSource(MAP_LEVEL_SOURCE, {
            type: "geojson",
            data: { type: "FeatureCollection", features: [] },
          });

          MapLevels.forEach((level) => {
            map.addLayer({
              id: level,
              source: MAP_LEVEL_SOURCE,
              type: "symbol",
            });
          });
          setState({ lib, map });
        };
        map.on("load", onLoad);
        map.on("error", onError);
        mapSetupController.current.signal.addEventListener("abort", () => {
          map.off("load", onLoad);
          setTimeout(() => map.remove(), 50);
        });
      }
      if (lib === "maplibre") {
        const map = new maplibregl.Map({
          container,
          style: {
            version: 8,
            sources: {
              "basemap-raster-tiles": {
                type: "raster",
                tiles: BASE_MAP_TILES_URL.map(
                  (url) => `${url}${NEXT_HERE_API_KEY}`,
                ),
                tileSize: 256,
              },
            },
            layers: [
              {
                id: "base-map-tiles",
                type: "raster",
                source: "basemap-raster-tiles",
              },
            ],
            // TODO: Use Here Maps API (currently 404)
            // glyphs: "https://assets.vector.hereapi.com/fonts/{fontstack}/{range}.pbf",
            glyphs:
              "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
          },
          bounds: initialBoundsRef.current as maplibregl.LngLatBoundsLike,
          interactive,
          logoPosition: "bottom-left",
          attributionControl: false,
          renderWorldCopies: true,
          localIdeographFontFamily: "Public Sans",
          transformRequest: (url, resourceType) => {
            // @see https://github.com/typescript-eslint/typescript-eslint/issues/7908
            // @see https://github.com/maplibre/maplibre-gl-js/issues/3364
            // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            if (resourceType === "Tile" && url.includes("shared-athena")) {
              return {
                url,
                headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  Authorization: `Bearer ${authAccessTokenRef.current}`,
                },
              };
            }
          },
        });
        const onLoad = () => {
          map.addSource(MAP_LEVEL_SOURCE, {
            type: "geojson",
            data: { type: "FeatureCollection", features: [] },
          });

          MapLevels.forEach((level) => {
            map.addLayer({
              id: level,
              source: MAP_LEVEL_SOURCE,
              type: "symbol",
            });
          });
          setState({ lib, map });
        };
        map.on("load", onLoad);
        map.on("error", onError);
        mapSetupController.current.signal.addEventListener("abort", () => {
          map.off("load", onLoad);

          // NOTE: Calling this synchronously makes the effect cleanup in all child components throw.
          setTimeout(() => map.remove(), 50);
        });
      }
    },
    [accessToken, authAccessTokenRef, interactive, lib, tiles],
  );

  useEffect(() => {
    if (!TEST_MODE) return;

    if (state.lib === "mapbox") {
      window.mapbox = state.map;
    } else if (state.lib === "maplibre") {
      window.maplibre = state.map;
    }

    return () => {
      delete window[state.lib];
    };
  }, [state.lib, state.map]);

  useEffect(() => {
    if (!onBoundsChange || !state.map) return;
    const onMoveEnd = (event: MapboxEvent | MapLibreEvent) => {
      const bounds = event.target.getBounds();
      onBoundsChange([
        bounds.getWest(),
        bounds.getSouth(),
        bounds.getEast(),
        bounds.getNorth(),
      ]);
    };

    state.map.on("moveend", onMoveEnd);

    return () => {
      state.map?.off("moveend", onMoveEnd);
    };
  }, [onBoundsChange, state.map]);

  const value = useMemo(() => ({ register, ...state }), [register, state]);

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

export default MapProvider;
